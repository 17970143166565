require('../css/style.css');

window.addEventListener('DOMContentLoaded', run);

function run() {
    document.body.addEventListener('click', e => {
        if (e.target.classList.contains('faq-btn') || e.target.classList.contains('faq-title')) {
            toggleFaq(e.target, {
                collapseOthers: true
            });
        } else if (
            e.target.classList.contains('header-btn') ||
            e.target.classList.contains('item-earn-link')
        ) {
            scrollTo(e, 'top-games');
        } else if (e.target.classList.contains('item-upcoming-link')) {
            scrollTo(e, 'upcoming-games');
        } else if (e.target.classList.contains('item-faq-link')) {
            scrollTo(e, 'faq');
        } else if (e.target.classList.contains('item-about-us-link')) {
            scrollTo(e, 'about-us');
        } else if (e.target.classList.contains('item-roadmap-link')) {
            scrollTo(e, 'roadmap');
        } else if (e.target.classList.contains('m-readmore-btn')) {
            toggleReadMore(e.target, {
                parent: '.content',
                area: '.m-slide-down',
                wrapper: '.m-slide-down-measure',
                text: '.txt',
                image: '.img'
            });
        } else if (e.target.classList.contains('read-more')) {
            toggleReadMore(
                e.target,
                isMobile()
                    ? {
                          parent: '.content',
                          area: '.m-slide-down',
                          wrapper: '.m-slide-down-measure',
                          text: '.txt',
                          image: '.img',
                          nested: ['.desktop-slide-down']
                      }
                    : {
                          parent: '.content',
                          area: '.desktop-slide-down',
                          wrapper: '.desktop-slide-down-measure',
                          text: '.txt',
                          image: '.img'
                      }
            );
        }
    });

    new Slider({
        parent: '.game-description:nth-of-type(2) .footer',
        slideArea: '.images',
        slides: '.images img',
        nav: '.m-slider-nav'
    });

    new Slider({
        parent: '.game-description:nth-of-type(3) .footer',
        slideArea: '.images',
        slides: '.images img',
        nav: '.m-slider-nav'
    });

    new Slider({
        parent: '.game-description:nth-of-type(4) .footer',
        slideArea: '.images',
        slides: '.images img',
        nav: '.m-slider-nav'
    });

    loadDynamicData();
    lazyLoadYoutube();
}

function isMobile() {
    const detector = document.querySelector('.mobile-detector');
    const styles = window.getComputedStyle(detector);
    return styles.getPropertyValue('display') === 'none';
}

function scrollTo(e, id) {
    e.preventDefault();
    const element = document.getElementById(id);
    element.scrollIntoView({
        behavior: 'smooth'
    });
}

function toggleFaq(faqBtn, options = {}) {
    const faq = faqBtn.closest('.faq');
    if (faq.classList.contains('collapsed')) {
        if (Object(options).collapseOthers) {
            const wrapper = faqBtn.closest('.faq-wrapper');
            const faqs = wrapper.querySelectorAll('.faq');
            if (faqs.length) {
                faqs.forEach(item => {
                    item.classList.remove('expanded');
                    item.classList.add('collapsed');
                });
            }
        }

        faq.classList.remove('collapsed');
        faq.classList.add('expanded');
    } else {
        faq.classList.remove('expanded');
        faq.classList.add('collapsed');
    }
}

function toggleReadMore(clicked, options) {
    const toggleArea = clicked.closest(options.parent).querySelector(options.area);

    if (toggleArea.clientHeight) {
        toggleArea.style.height = 0;
        clicked.querySelector(options.text).textContent = 'Read More';
        clicked.querySelector(options.image).style.transform = 'rotate(0deg)';
    } else {
        const wrapper = toggleArea.querySelector(options.wrapper || 'div');

        // Handle any nested read mores.
        // This will trigger when device is mobile and there is nested desktop read more.
        if (Array.isArray(options.nested)) {
            options.nested.forEach(selector => {
                const nestedAreas = wrapper.querySelectorAll(selector);
                if (nestedAreas.length) {
                    nestedAreas.forEach(nestedArea => (nestedArea.style.height = 'auto'));
                }
            });
        }

        toggleArea.style.height = wrapper.clientHeight + 'px';
        clicked.querySelector(options.text).textContent = 'Collapse';
        clicked.querySelector(options.image).style.transform = 'rotate(180deg)';
    }
}

async function loadDynamicData() {
    const lsAvailable = lsTest();

    async function loadFromOpenSea() {
        const collections = [
            'axie',
            'decentraland',
            'league-of-kingdoms',
            'revv-motorsport-inventory',
            'gods-unchained-collectibles'
        ];

        const promises = [];

        for (let i = 0, ii = collections.length; i < ii; i++) {
            const collectionSlug = collections[i];

            if (lsAvailable) {
                const cached = JSON.parse(localStorage.getItem(collectionSlug));
                if (cached) {
                    const cachedDate = new Date(cached.date);
                    const currentDate = new Date();
                    if (
                        cachedDate.getFullYear() === currentDate.getFullYear() &&
                        cachedDate.getMonth() === currentDate.getMonth() &&
                        cachedDate.getDate() === currentDate.getDate()
                    ) {
                        promises.push(
                            Promise.resolve({
                                ...cached.collection,
                                cached: true,
                                slug: collectionSlug
                            })
                        );
                        continue;
                    }
                }
            }

            promises.push(get('https://api.opensea.io/api/v1/collection/' + collectionSlug));
        }

        const [axie, decentraland, leagueOfKingdoms, revv, godsUnchained] = await Promise.all(
            promises
        );

        console.log(axie, decentraland, leagueOfKingdoms, revv, godsUnchained);
        const output = [];

        axie.cached ? output.push(axie) : prepareOutput(output, axie, 'ETH');
        // prettier-ignore
        decentraland.cached ? output.push(decentraland): prepareOutput(output, decentraland, 'ETH');
        revv.cached ? output.push(revv) : prepareOutput(output, revv, 'Polygon');
        // prettier-ignore
        if (godsUnchained.cached) {
            output.push(godsUnchained);
        } else {
            godsUnchained.collection.name = godsUnchained.collection.name
                .replace('Collectibles', '')
                .trim();
            prepareOutput(output, godsUnchained, 'ETH');
        }

        if (leagueOfKingdoms.cached) {
            output.push(leagueOfKingdoms);
        } else {
            leagueOfKingdoms.collection.name = leagueOfKingdoms.collection.name
                .replace('(Ethereum)', '')
                .trim();
            prepareOutput(output, leagueOfKingdoms, 'ETH');
        }

        function prepareOutput(out, item, blockchain) {
            console.log(item.collection.name, item.collection.stats.total_volume);
            const collection = {
                name: item.collection.name,
                slug: item.collection.slug,
                blockchain,
                logo: item.collection.image_url,
                website: item.collection.external_url
                    ? item.collection.external_url
                    : 'https://opensea.io/collection/' + item.collection.slug,
                marketCap: formatNumber(item.collection.stats.market_cap, 0),
                volume: formatNumber(item.collection.stats.total_volume, 0),
                owners: formatNumber(item.collection.stats.num_owners, 0)
            };

            out.push(collection);

            if (lsAvailable) {
                localStorage.setItem(
                    collection.slug,
                    JSON.stringify({ date: new Date(), collection })
                );
            }
        }

        return output;
    }

    async function loadFromCoinMarketCap() {
        const [metaverse, gaming] = await Promise.all([
            get(
                'https://api.coinmarketcap.com/data-api/v3/nft/collections?start=0&limit=2&category=Metaverse&sort=volume&desc=true&period=3'
            ),
            get(
                'https://api.coinmarketcap.com/data-api/v3/nft/collections?start=0&limit=3&category=Gaming&sort=volume&desc=true&period=3'
            )
        ]);

        const output = [];

        metaverse.data.collections.concat(gaming.data.collections).forEach(item => {
            output.push({
                name: item.name,
                blockchain: item.blockchain,
                logo: item.logo,
                website: item.website,
                marketCap: formatNumber(item.allTime.marketCap, 0),
                volume: formatNumber(item.allTime.volume, 0),
                owners: formatNumber(item.owners, 0)
            });
        });

        return output;
    }

    const [openseaData, coinMarketCapData] = await Promise.all([
        loadFromOpenSea(),
        loadFromCoinMarketCap()
    ]);

    const tbody = document.querySelector('.statistics table tbody');
    const collections = openseaData
        .concat(coinMarketCapData)
        .sort((a, b) => parseFloat(b.owners.replace(/,/g, '')) - parseFloat(a.owners.replace(/,/g, '')));
    collections.forEach(item => {
        console.log(item.name);
        const tr = document.createElement('tr');
        tr.innerHTML = `
        <td></td>
        <td>
            <div class="game-col">
                <a href="${item.website}" rel="noreferrer noopener" target="_blank" class="game-logo" style="background-image: url(${item.logo})"></a>
                <div class="game-details">
                    <a href="${item.website}" rel="noreferrer noopener" target="_blank">${item.name}</a>
                    <p>${item.blockchain}</p>
                </div>
            </div>
        </td>
        <td>${item.owners}</td>
        <td>${item.volume}</td>
        <td>${item.marketCap}</td>
        <td></td>`;
        tbody.appendChild(tr);
    });
}

function formatNumber(n, decimals) {
    return Math.round(parseFloat(n), decimals).toLocaleString('en-US');
}

function get(url) {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.onreadystatechange = function () {
            if (req.readyState == 4 && req.status == 200) {
                try {
                    resolve(JSON.parse(req.responseText));
                } catch (error) {
                    resolve(false);
                }
            }
        };
        req.open('GET', url, true);
        req.send(null);
    });
}

function lazyLoadYoutube() {
    const videos = document.querySelectorAll('.youtube');

    for (var i = 0; i < videos.length; i++) {
        const video = videos[i];
        const source = 'https://img.youtube.com/vi/' + video.dataset.ytid + '/sddefault.jpg';
        const image = new Image();
        image.src = source;
        image.alt = 'Youtube video';
        image.addEventListener(
            'load',
            (function () {
                video.appendChild(image);
            })(i)
        );

        video.addEventListener('click', function () {
            var iframe = document.createElement('iframe');

            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', '');
            iframe.setAttribute('allow', 'autoplay; fullscreen');
            iframe.setAttribute(
                'src',
                'https://www.youtube-nocookie.com/embed/' +
                    this.dataset.ytid +
                    '?rel=0&showinfo=0&autoplay=1'
            );

            this.innerHTML = '';
            this.appendChild(iframe);
        });
    }
}

function lsTest() {
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

class Slider {
    constructor(params) {
        this.parent = document.querySelector(params.parent);
        this.slideArea = this.parent.querySelector(params.slideArea);
        this.slides = Array.from(this.parent.querySelectorAll(params.slides));
        this.nav = this.parent.querySelector(params.nav);
        this.navItems = Array.from(this.nav.querySelectorAll(params.navItems || 'li'));
        this.navButtonsParent = this.parent.querySelector(params.navButtons);
        this.navButtons = this.navButtonsParent
            ? Array.from(this.navButtonsParent.querySelectorAll(params.navButton || 'a'))
            : [];
        this.activeIndex = 0;
        this.touchstartX = 0;
        this.touchstartY = 0;
        this.touchendX = 0;
        this.touchendY = 0;

        this.init();
    }

    init() {
        window.addEventListener('resize', e => this.slideToActiveButton());

        this.slideArea.addEventListener(
            'touchstart',
            event => {
                this.touchstartX = event.changedTouches[0].screenX;
                this.touchstartY = event.changedTouches[0].screenY;
            },
            false
        );

        this.slideArea.addEventListener(
            'touchend',
            event => {
                this.touchendX = event.changedTouches[0].screenX;
                this.touchendY = event.changedTouches[0].screenY;
                this.handleSwipe(event);
            },
            false
        );

        this.navItems.forEach(item => {
            item.addEventListener('click', e => {
                const idx = this.navItems.indexOf(e.target);
                this.slideTo(idx);
                this.slideToActiveButton();
            });
        });

        if (this.navButtonsParent) {
            this.navButtonsParent.addEventListener(
                'touchstart',
                event => {
                    this.touchstartX = event.changedTouches[0].screenX;
                    this.touchstartY = event.changedTouches[0].screenY;
                },
                false
            );

            this.navButtonsParent.addEventListener(
                'touchend',
                event => {
                    this.touchendX = event.changedTouches[0].screenX;
                    this.touchendY = event.changedTouches[0].screenY;
                    this.handleSwipe(event);
                },
                false
            );

            this.navButtons.forEach(item => {
                function disablePointerEvents(children) {
                    for (let i = 0, ii = children.length; i < ii; i++) {
                        const child = children[i];
                        child.style.pointerEvents = 'none';
                        if (child.children.length) {
                            disablePointerEvents(child.children);
                        }
                    }
                }

                disablePointerEvents(item.children);

                item.addEventListener(
                    'click',
                    e => {
                        e.stopPropagation();
                        const idx = this.navButtons.indexOf(e.target);
                        this.slideTo(idx);
                        this.slideToActiveButton();
                    },
                    {}
                );
            });
        }
    }

    handleSwipe(event) {
        var caseCaptured = false;

        const { touchstartX, touchstartY, touchendX, touchendY } = this;

        if (touchendX < touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe left
            caseCaptured = true;
            if (this.activeIndex < this.slides.length - 1) {
                this.slideTo(this.activeIndex + 1);
                this.slideToActiveButton();
            }
        } else if (touchendX > touchstartX && Math.abs(touchendX - touchstartX) > 30) {
            // Swipe right
            caseCaptured = true;

            if (!this.activeIndex) {
                // If user swipes left and the slider is on the first image -> do nothing
                return;
            }

            this.slideTo(this.activeIndex - 1);
            this.slideToActiveButton();
        }

        if (caseCaptured) {
            event.preventDefault();
        }
    }

    slideTo(idx) {
        const reset = item => item.classList.remove('active');

        this.navItems.forEach(reset);
        this.navButtons.forEach(reset);
        this.slides.forEach(reset);

        this.slides[idx] && this.slides[idx].classList.add('active');
        this.navItems[idx] && this.navItems[idx].classList.add('active');
        this.navButtons[idx] && this.navButtons[idx].classList.add('active');
        this.activeIndex = idx;
    }

    slideToActiveButton() {
        if (!this.navButtons.length) {
            return;
        }

        if (!this.isMobile()) {
            const firstButton = this.navButtons[0];
            firstButton.style.removeProperty
                ? firstButton.style.removeProperty('margin-left')
                : firstButton.style.removeAttribute('margin-left');
            return;
        }

        const { navButtonsParent, navButtons } = this;

        setTimeout(() => {
            const activeItem = navButtons.find(x => x.classList.contains('active'));
            const activeIdx = navButtons.indexOf(activeItem);
            const activeItemW = activeItem.offsetWidth;
            const parentW = navButtonsParent.offsetWidth;
            const marginLeft =
                activeIdx === 0
                    ? (parentW / 2 - activeItemW / 2).toFixed()
                    : (function measureItemsBefore() {
                          let value = 0;
                          for (let i = 0; i < activeIdx; i++) {
                              value += navButtons[i].offsetWidth;
                          }

                          const margin =
                              parseInt(window.getComputedStyle(navButtons[0]).marginRight) * 2;
                          const itemsWidth = value + margin * (activeIdx - 1);
                          const parentWidth = navButtonsParent.offsetWidth / 2;
                          const x = parentWidth - (margin + activeItemW / 2);

                          return -(itemsWidth - x);
                      })();

            navButtons[0].style.marginLeft = marginLeft + 'px';
        }, 50);
    }

    isMobile() {
        const detector = document.querySelector('.mobile-detector');
        const styles = window.getComputedStyle(detector);
        return styles.getPropertyValue('display') === 'none';
    }
}
